import React from "react";
import { graphql, useStaticQuery } from "gatsby";

import CareersItem from "./CareersItem";


const CareersStack = ({
  heading
}) => {
  const { allMarkdownRemark } = useStaticQuery(
    graphql`
      query {
        allMarkdownRemark(
          filter: {
            frontmatter: {
              template: {
                eq: "career"
              }
            }
          }
          sort: {
            fields: [frontmatter___date]
            order: DESC
          }
        ) {
          edges {
            node {
              frontmatter {
                title
                location
              }
              fields {
                slug
              }
            }
          }
        }
      }
    `
  );

  const careersItems = allMarkdownRemark.edges.map(node => {
    return (
      <CareersItem 
        title={ node.node.frontmatter.title }
        location={ node.node.frontmatter.location }
        pageLink={ node.node.fields.slug }
      />
    )
  });
  
  return (
    <section className="container mx-auto my-16">
      { heading &&
        <h2 className="mb-8 px-6 lg:px-28 text-3xl font-thin">{ heading }</h2>
      }
      <div className="lg:grid grid-cols-1 lg:gap-4 px-6 lg:px-28">
        { careersItems }
      </div>
    </section>
  )
}

export default CareersStack;