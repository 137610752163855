import React from "react";
import { Link } from "gatsby";
import BackgroundImage from "gatsby-background-image";
import PropTypes from "prop-types";


const CareersItem = ({ 
  title,
  location,
  pageLink
}) => {
  return (
    <div>
      <Link
        to={ pageLink }
      >
        <div className="flex flex-col justify-start space-y-[12px] font-thin transition-all hover:opacity-60">
          <h3 className="text-2xl text-blue-light">
            <span>{ title }</span>
          </h3>
          <p className="text-base tw-opacity-80">{ location }</p>
        </div>
      </Link>
    </div>
  )
}

CareersItem.propTypes = {
  title: PropTypes.string,
  location: PropTypes.string,
  pageLink: PropTypes.string,
}

CareersItem.defaultProps = {
  title: '',
  location: '',
  pageLink: '', 
}

export default CareersItem;
