import React from "react";


const Hero = ({
  title,
  subtitle,
  colorScheme
}) => {
  let style = {};

  if (colorScheme === "dark") {
    style = {
      container: "pt-28 lg:pt-32 bg-blue-dark text-white",
      title: "text-3xl md:text-4xl lg:text-5xl font-thin tracking-wide",
    }
  } else {
    style = {
      container: "pt-28 lg:pt-32 bg-white",
      title: "text-3xl md:text-4xl lg:text-5xl font-thin tracking-wide text-blue-dark",
    }
  }
  
  return (
    <section className="w-screen">
      <div className={ style.container }>
        <div className="container mx-auto px-6 pt-4 lg:pt-24 mb-8 lg:px-28 xl:px-80">
          <h1 className={ style.title }>
            <span className="block">{ title }</span>
          </h1>
          <p className="my-8 font-thin lg:text-lg">
            { subtitle }
          </p>
        </div>
      </div>
    </section>
  )
}

export default Hero;
