import React from "react";
import { graphql, useStaticQuery } from "gatsby";

import CallToAction from "../../components/CallToAction/CallToAction";
import CareersStack from "../../components/Stack/Careers/CareersStack";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import Hero from "../../components/Hero/Hero";
import Layout from "../../components/Layout/Layout";


const CareersPage = () => {
  return (
    <Layout
      title="Careers"
      description="KND Complex Litigation is always interested in hearing from bright people with 
      outstanding academic backgrounds who have an interest in class actions litigation and 
      wish to join our growing team in Toronto."
    >
      <Header
        colorScheme="light"
      />

      <Hero
        title="Careers"
        subtitle="KND Complex Litigation is always interested in hearing from bright people with 
        outstanding academic backgrounds who have an interest in class actions litigation and 
        wish to join our growing team in Toronto."
        contactInfo={ false }
        colorScheme="light"
      />

      <CareersStack
        heading="Current Open Positions:"
      />

      <CallToAction />

      <Footer />
    </Layout>
  );
}

export default CareersPage;